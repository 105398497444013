import { z } from 'zod';

import { FontNames } from './theme-css-variables';
import { ParametersSchema } from './theme-parameters';
import { PresetNames } from '../constants';

export const PresetNameEnum = z.enum([
  PresetNames.THE_LEAP,
  PresetNames.AURA,
  PresetNames.MYSTIQUE,
  PresetNames.NOVA,
  PresetNames.EPIC,
  PresetNames.LUNA,
  PresetNames.TUNDRA,
  PresetNames.MINIMAL,
  PresetNames.PULSE,
  PresetNames.CANDY,
]);

export type PresetName = z.infer<typeof PresetNameEnum>;

export const PRESET_DEFAULTS = {
  coverImageUrl: {
    [PresetNames.THE_LEAP]:
      'https://images.unsplash.com/photo-1627325071486-0b1e3083294a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.AURA]:
      'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.MYSTIQUE]:
      'https://images.unsplash.com/photo-1614241580814-477d221ebaeb?q=80&w=2790&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.NOVA]:
      'https://images.unsplash.com/photo-1567016376408-0226e4d0c1ea?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.EPIC]:
      'https://images.unsplash.com/photo-1601645191163-3fc0d5d64e35?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.LUNA]:
      'https://images.unsplash.com/photo-1524511498335-b0ed6b8e9a69?q=80&w=1200&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.TUNDRA]:
      'https://images.unsplash.com/photo-1505839673365-e3971f8d9184?q=80&w=800&h=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.MINIMAL]:
      'https://images.unsplash.com/photo-1479064555552-3ef4979f8908?q=80&w=800&h=500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.PULSE]:
      'https://images.unsplash.com/photo-1666951195443-1a983a5a62d8?q=80&w=1760&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    [PresetNames.CANDY]:
      'https://images.unsplash.com/photo-1458682625221-3a45f8a844c7?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
};

export const ColorPresetSchema = z.object({
  name: z.string(),
  bgStyle: z.string(),
  bgHue: z.number(),
  bgLum: z.number(),
  uiHue: z.number(),
  uiLum: z.number(),
  saturation: z.number(),
  fontColor: z.string(),
});
export type ColorPreset = z.infer<typeof ColorPresetSchema>;

export const ColorPresetsSchema = z.array(ColorPresetSchema);

export const PresetSchema = z.object({
  parameters: ParametersSchema,
  colorVariations: ColorPresetsSchema,
  meta: z.object({
    previewImageId: z.string(),
    descriptor: z.string(),
  }),
});

export type Preset = z.infer<typeof PresetSchema>;

export const PresetNova: Preset = {
  meta: {
    previewImageId: '61214879-f71f-4944-4272-2b88c92aa000',
    descriptor: 'A light, modern, feminine theme.',
  },
  colorVariations: [
    {
      name: 'lilac',
      bgStyle: 'solid',
      bgHue: 40,
      bgLum: 92,
      uiHue: 314,
      uiLum: 81,
      saturation: 20,
      fontColor: 'blue-4',
    },
    {
      name: 'teal',
      bgStyle: 'solid',
      bgHue: 36,
      bgLum: 85,
      uiHue: 179,
      uiLum: 72,
      saturation: 14,
      fontColor: 'sky-2',
    },
    {
      name: 'sage',
      bgStyle: 'solid',
      bgHue: 148,
      bgLum: 90,
      uiHue: 125,
      uiLum: 81,
      saturation: 16,
      fontColor: 'sky-1',
    },
    {
      name: 'blue',
      bgStyle: 'solid',
      bgHue: 192,
      bgLum: 82,
      uiHue: 182,
      uiLum: 66,
      saturation: 28,
      fontColor: 'sky-1',
    },
    {
      name: 'saphire',
      bgStyle: 'solid',
      bgHue: 170,
      bgLum: 78,
      uiHue: 209,
      uiLum: 45,
      saturation: 23,
      fontColor: 'blue-4',
    },
    {
      name: 'maroon',
      bgStyle: 'solid',
      bgHue: 0,
      bgLum: 74,
      uiHue: 346,
      uiLum: 46,
      saturation: 16,
      fontColor: 'gray-2',
    },
  ],
  parameters: {
    preset: PresetNames.NOVA,
    layout: 'banner',
    colorVariation: 'lilac',
    spacing: 32,
    blend: 50,
    displayFont: FontNames.PLAYFAIR_DISPLAY,
    bodyFont: FontNames.CRIMSON_PRO,
    bgStyle: 'solid',
    bgHue: 40,
    bgLum: 92,
    uiHue: 314,
    uiLum: 81,
    saturation: 30,
    fontColor: 'purple-1',
    style: 'raised',
    styleStrength: 8,
    borderRadius: 100,
    fontSize: 60,
    textStrength: 35,
  },
};

export const PresetEpic: Preset = {
  meta: {
    previewImageId: '9a750d90-6402-45c5-f268-1c60a7247a00',
    descriptor: 'A bold, comic book style theme.',
  },
  colorVariations: [
    {
      name: 'happy',
      bgStyle: 'solid',
      bgHue: 48,
      bgLum: 61,
      uiHue: 207,
      uiLum: 69,
      saturation: 80,
      fontColor: 'gray-1',
    },
    {
      name: 'royal',
      bgStyle: 'solid',
      bgHue: 262,
      bgLum: 67,
      uiHue: 181,
      uiLum: 77,
      saturation: 61,
      fontColor: 'gray-1',
    },
    {
      name: 'stable',
      bgStyle: 'solid',
      bgHue: 124,
      bgLum: 58,
      uiHue: 33,
      uiLum: 81,
      saturation: 49,
      fontColor: 'gray-1',
    },
    {
      name: 'night',
      bgStyle: 'solid',
      bgHue: 227,
      bgLum: 67,
      uiHue: 14,
      uiLum: 77,
      saturation: 88,
      fontColor: 'gray-1',
    },
    {
      name: 'action',
      bgStyle: 'solid',
      bgHue: 0,
      bgLum: 67,
      uiHue: 191,
      uiLum: 67,
      saturation: 93,
      fontColor: 'gray-1',
    },
    {
      name: 'hero',
      bgStyle: 'solid',
      bgHue: 29,
      bgLum: 61,
      uiHue: 199,
      uiLum: 86,
      saturation: 87,
      fontColor: 'gray-1',
    },
  ],
  parameters: {
    preset: PresetNames.EPIC,
    layout: 'banner',
    spacing: 24,
    blend: 50,
    displayFont: FontNames.OUTFIT,
    bodyFont: FontNames.OUTFIT,
    colorVariation: 'happy',
    bgStyle: 'solid',
    bgHue: 48,
    bgLum: 61,
    uiHue: 207,
    uiLum: 69,
    saturation: 80,
    fontColor: 'gray-1',
    style: 'box',
    styleStrength: 66,
    borderRadius: 100,
    fontSize: 27,
    textStrength: 18,
  },
};

export const PresetAura: Preset = {
  meta: {
    previewImageId: '083c489d-e246-4432-6bad-1aa074b16200',
    descriptor: 'A glowy, pink theme with gradients.',
  },
  colorVariations: [
    {
      name: 'sunrise',
      bgStyle: 'gradient',
      bgHue: 335,
      bgLum: 73,
      uiHue: 235,
      uiLum: 56,
      saturation: 56,
      fontColor: 'blue-4',
    },
    {
      name: 'ocean',
      bgStyle: 'gradient',
      bgHue: 215,
      bgLum: 67,
      uiHue: 179,
      uiLum: 50,
      saturation: 50,
      fontColor: 'blue-4',
    },
    {
      name: 'bi',
      bgStyle: 'gradient',
      bgHue: 185,
      bgLum: 67,
      uiHue: 0,
      uiLum: 53,
      saturation: 44,
      fontColor: 'red-3',
    },
    {
      name: 'lush',
      bgStyle: 'gradient',
      bgHue: 125,
      bgLum: 67,
      uiHue: 174,
      uiLum: 77,
      saturation: 45,
      fontColor: 'gray-1',
    },
    {
      name: 'field',
      bgStyle: 'gradient',
      bgHue: 49,
      bgLum: 70,
      uiHue: 140,
      uiLum: 44,
      saturation: 41,
      fontColor: 'sky-1',
    },
    {
      name: 'sunset',
      bgStyle: 'gradient',
      bgHue: 27,
      bgLum: 76,
      uiHue: 0,
      uiLum: 62,
      saturation: 56,
      fontColor: 'brown',
    },
  ],
  parameters: {
    preset: PresetNames.AURA,
    colorVariation: 'sunrise',
    layout: 'stack',
    spacing: 54,
    blend: 50,
    displayFont: FontNames.INCONSOLATA,
    bodyFont: FontNames.INCONSOLATA,
    bgStyle: 'gradient',
    bgHue: 27,
    bgLum: 76,
    uiHue: 0,
    uiLum: 62,
    saturation: 56,
    fontColor: 'yellow-1',
    style: 'flat',
    styleStrength: 80,
    borderRadius: 100,
    fontSize: 50,
    textStrength: 70,
  },
};

export const PresetTundra: Preset = {
  meta: {
    previewImageId: '6e78a6d8-655c-47e6-f187-dcbbb93d7600',
    descriptor: 'A clean, crisp, mainly blue and gray theme.',
  },
  colorVariations: [
    {
      name: 'ice',
      bgStyle: 'solid',
      bgHue: 0,
      bgLum: 98,
      uiHue: 199,
      uiLum: 71,
      saturation: 21,
      fontColor: 'gray-2',
    },
    {
      name: 'storm',
      bgStyle: 'solid',
      bgHue: 211,
      bgLum: 89,
      uiHue: 211,
      uiLum: 73,
      saturation: 11,
      fontColor: 'gray-1',
    },
    {
      name: 'mist',
      bgStyle: 'solid',
      bgHue: 206,
      bgLum: 84,
      uiHue: 204,
      uiLum: 66,
      saturation: 26,
      fontColor: 'gray-1',
    },
    {
      name: 'rock',
      bgStyle: 'solid',
      bgHue: 219,
      bgLum: 26,
      uiHue: 221,
      uiLum: 71,
      saturation: 18,
      fontColor: 'gray-8',
    },
    {
      name: 'ocean',
      bgStyle: 'solid',
      bgHue: 222,
      bgLum: 20,
      uiHue: 223,
      uiLum: 83,
      saturation: 39,
      fontColor: 'gray-6',
    },
    {
      name: 'mountain',
      bgStyle: 'solid',
      bgHue: 222,
      bgLum: 12,
      uiHue: 190,
      uiLum: 80,
      saturation: 21,
      fontColor: 'gray-6',
    },
  ],
  parameters: {
    preset: PresetNames.TUNDRA,
    layout: 'stack',
    colorVariation: 'ice',
    spacing: 56,
    blend: 50,
    displayFont: FontNames.CRIMSON_PRO,
    bodyFont: FontNames.CRIMSON_PRO,
    style: 'book',
    styleStrength: 43,
    bgStyle: 'solid',
    bgHue: 0,
    bgLum: 98,
    uiHue: 199,
    uiLum: 71,
    saturation: 21,
    fontColor: 'gray-2',
    borderRadius: 100,
    fontSize: 75,
    textStrength: 15,
  },
};

export const PresetMystique: Preset = {
  meta: {
    previewImageId: 'dc7cced4-a110-492b-2d2b-52e286e99c00',
    descriptor: 'A dark, rich, gradient theme.',
  },
  colorVariations: [
    {
      name: 'moonlight',
      bgStyle: 'gradient',
      bgHue: 226,
      bgLum: 26,
      uiHue: 327,
      uiLum: 35,
      saturation: 24,
      fontColor: 'blue-2',
    },
    {
      name: 'iron',
      bgStyle: 'gradient',
      bgHue: 212,
      bgLum: 36,
      uiHue: 220,
      uiLum: 18,
      saturation: 41,
      fontColor: 'teal-2',
    },
    {
      name: 'deep',
      bgStyle: 'gradient',
      bgHue: 211,
      bgLum: 14,
      uiHue: 210,
      uiLum: 25,
      saturation: 52,
      fontColor: 'sky-3',
    },
    {
      name: 'slate',
      bgStyle: 'gradient',
      bgHue: 212,
      bgLum: 33,
      uiHue: 210,
      uiLum: 10,
      saturation: 28,
      fontColor: 'sky-3',
    },
    {
      name: 'swamp',
      bgStyle: 'gradient',
      bgHue: 213,
      bgLum: 26,
      uiHue: 69,
      uiLum: 54,
      saturation: 34,
      fontColor: 'yellow-4',
    },
    {
      name: 'crave',
      bgStyle: 'gradient',
      bgHue: 216,
      bgLum: 29,
      uiHue: 168,
      uiLum: 44,
      saturation: 38,
      fontColor: 'yellow-3',
    },
  ],
  parameters: {
    preset: PresetNames.MYSTIQUE,
    layout: 'banner',
    colorVariation: 'moonlight',
    spacing: 32,
    blend: 50,
    displayFont: FontNames.SPACE_GROTESK,
    bodyFont: FontNames.SPACE_GROTESK,
    style: 'raised',
    styleStrength: 54,
    bgStyle: 'gradient',
    bgHue: 216,
    bgLum: 29,
    uiHue: 0,
    uiLum: 44,
    saturation: 38,
    fontColor: 'gray-5',
    borderRadius: 100,
    fontSize: 23,
    textStrength: 71,
  },
};

export const PresetLuna: Preset = {
  meta: {
    previewImageId: '38a91afe-cec9-409c-2923-aa46055d6300',
    descriptor: 'A pastel, lined theme.',
  },
  colorVariations: [
    {
      name: 'pink',
      bgStyle: 'solid',
      bgHue: 0,
      bgLum: 94,
      uiHue: 0,
      uiLum: 82,
      saturation: 54,
      fontColor: 'brown',
    },
    {
      name: 'yellow',
      bgStyle: 'solid',
      bgHue: 40,
      bgLum: 93,
      uiHue: 80,
      uiLum: 79,
      saturation: 53,
      fontColor: 'gray-1',
    },
    {
      name: 'green',
      bgStyle: 'solid',
      bgHue: 79,
      bgLum: 94,
      uiHue: 148,
      uiLum: 80,
      saturation: 53,
      fontColor: 'sky-1',
    },
    {
      name: 'blue',
      bgStyle: 'solid',
      bgHue: 208,
      bgLum: 92,
      uiHue: 304,
      uiLum: 85,
      saturation: 52,
      fontColor: 'blue-4',
    },
    {
      name: 'purple',
      bgStyle: 'solid',
      bgHue: 292,
      bgLum: 93,
      uiHue: 209,
      uiLum: 83,
      saturation: 51,
      fontColor: 'sky-1',
    },
    {
      name: 'white',
      bgStyle: 'solid',
      bgHue: 360,
      bgLum: 98,
      uiHue: 0,
      uiLum: 79,
      saturation: 30,
      fontColor: 'gray-1',
    },
  ],
  parameters: {
    preset: PresetNames.LUNA,
    layout: 'stack',
    spacing: 50,
    blend: 50,
    displayFont: FontNames.NOTO_SERIF,
    bodyFont: FontNames.NOTO_SERIF,
    style: 'lined',
    styleStrength: 20,
    colorVariation: 'pink',
    bgStyle: 'solid',
    bgHue: 0,
    bgLum: 94,
    uiHue: 0,
    uiLum: 82,
    saturation: 54,
    fontColor: 'brown',
    borderRadius: 100,
    fontSize: 30,
    textStrength: 25,
  },
};

export const PresetMinimal: Preset = {
  meta: {
    previewImageId: '3dc8738c-b4d2-4f32-50ea-d13f1fbb3200',
    descriptor: 'A minimalistic theme.',
  },
  colorVariations: [
    {
      name: 'black',
      bgStyle: 'solid',
      bgHue: 214,
      bgLum: 100,
      uiHue: 212,
      uiLum: 14,
      saturation: 53,
      fontColor: 'gray-1',
    },
    {
      name: 'blue',
      bgStyle: 'solid',
      bgHue: 222,
      bgLum: 100,
      uiHue: 226,
      uiLum: 50,
      saturation: 60,
      fontColor: 'gray-1',
    },
    {
      name: 'red',
      bgStyle: 'solid',
      bgHue: 36,
      bgLum: 100,
      uiHue: 5,
      uiLum: 52,
      saturation: 62,
      fontColor: 'gray-1',
    },
    {
      name: 'mint',
      bgStyle: 'solid',
      bgHue: 148,
      bgLum: 100,
      uiHue: 108,
      uiLum: 80,
      saturation: 50,
      fontColor: 'gray-1',
    },
    {
      name: 'teal',
      bgStyle: 'solid',
      bgHue: 313,
      bgLum: 100,
      uiHue: 180,
      uiLum: 64,
      saturation: 50,
      fontColor: 'gray-1',
    },
    {
      name: 'purple',
      bgStyle: 'solid',
      bgHue: 33,
      bgLum: 100,
      uiHue: 290,
      uiLum: 80,
      saturation: 77,
      fontColor: 'gray-1',
    },
  ],
  parameters: {
    preset: PresetNames.MINIMAL,
    layout: 'compact',
    spacing: 58,
    blend: 50,
    displayFont: FontNames.WORK_SANS,
    bodyFont: FontNames.WORK_SANS,
    style: 'flat',
    colorVariation: 'black',
    bgStyle: 'solid',
    bgHue: 222,
    bgLum: 99,
    uiHue: 222,
    uiLum: 50,
    saturation: 75,
    fontColor: 'gray-1',
    styleStrength: 31,
    borderRadius: 100,
    fontSize: 14,
    textStrength: 25,
  },
};

export const PresetPulse: Preset = {
  meta: {
    previewImageId: 'bd5fec1f-8f9b-4519-7a22-464ffa5beb00',
    descriptor: 'A neon sign type theme, similar to Tron Legacy.',
  },
  colorVariations: [
    {
      name: 'electric',
      bgStyle: 'gradient',
      bgHue: 226,
      bgLum: 0,
      uiHue: 218,
      uiLum: 32,
      saturation: 54,
      fontColor: 'sky-3',
    },
    {
      name: 'bio',
      bgStyle: 'gradient',
      bgHue: 126,
      bgLum: 0,
      uiHue: 95,
      uiLum: 24,
      saturation: 43,
      fontColor: 'lime-2',
    },
    {
      name: 'night',
      bgStyle: 'gradient',
      bgHue: 262,
      bgLum: 0,
      uiHue: 271,
      uiLum: 25,
      saturation: 54,
      fontColor: 'blue-2',
    },
    {
      name: 'peace',
      bgStyle: 'gradient',
      bgHue: 27,
      bgLum: 0,
      uiHue: 24,
      uiLum: 25,
      saturation: 31,
      fontColor: 'yellow-3',
    },
    {
      name: 'winter',
      bgStyle: 'gradient',
      bgHue: 230,
      bgLum: 0,
      uiHue: 234,
      uiLum: 43,
      saturation: 10,
      fontColor: 'blue-1',
    },
    {
      name: 'sunset',
      bgStyle: 'gradient',
      bgHue: 360,
      bgLum: 0,
      uiHue: 360,
      uiLum: 13,
      saturation: 45,
      fontColor: 'red-2',
    },
  ],
  parameters: {
    preset: PresetNames.PULSE,
    layout: 'banner',
    colorVariation: 'eletric',
    spacing: 32,
    blend: 50,
    displayFont: FontNames.INTER,
    bodyFont: FontNames.INTER,
    style: 'lined',
    styleStrength: 50,
    bgStyle: 'gradient',
    bgHue: 226,
    bgLum: 0,
    uiHue: 218,
    uiLum: 32,
    saturation: 54,
    fontColor: 'sky-3',
    borderRadius: 100,
    fontSize: 10,
    textStrength: 25,
  },
};

export const PresetCandy: Preset = {
  meta: {
    previewImageId: '795cdd33-9e4f-4282-a462-45cd30214300',
    descriptor: 'A complementary color, pastel based theme.',
  },
  colorVariations: [
    {
      name: 'rockets',
      bgStyle: 'solid',
      bgHue: 178,
      bgLum: 85,
      uiHue: 0,
      uiLum: 75,
      saturation: 23,
      fontColor: 'red-3',
    },
    {
      name: 'm&ms',
      bgStyle: 'solid',
      bgHue: 223,
      bgLum: 39,
      uiHue: 0,
      uiLum: 53,
      saturation: 26,
      fontColor: 'pink-1',
    },
    {
      name: 'after8',
      bgStyle: 'solid',
      bgHue: 0,
      bgLum: 44,
      uiHue: 116,
      uiLum: 75,
      saturation: 28,
      fontColor: 'gray-7',
    },
    {
      name: 'cadburry egg',
      bgStyle: 'solid',
      bgHue: 96,
      bgLum: 89,
      uiHue: 233,
      uiLum: 74,
      saturation: 35,
      fontColor: 'blue-4',
    },
    {
      name: 'bubblegum',
      bgStyle: 'solid',
      bgHue: 24,
      bgLum: 91,
      uiHue: 0,
      uiLum: 80,
      saturation: 32,
      fontColor: 'brown',
    },
    {
      name: 'mint',
      bgStyle: 'solid',
      bgHue: 21,
      bgLum: 98,
      uiHue: 174,
      uiLum: 89,
      saturation: 25,
      fontColor: 'blue-4',
    },
  ],
  parameters: {
    preset: PresetNames.CANDY,
    layout: 'stack',
    colorVariation: 'rockets',
    spacing: 62,
    blend: 50,
    displayFont: FontNames.SOMETYPE_MONO,
    bodyFont: FontNames.SOMETYPE_MONO,
    style: 'box',
    styleStrength: 15,
    bgStyle: 'solid',
    bgHue: 178,
    bgLum: 85,
    uiHue: 0,
    uiLum: 75,
    saturation: 23,
    fontColor: 'red-3',
    borderRadius: 100,
    fontSize: 5,
    textStrength: 30,
  },
};

export const PresetTheLeap: Preset = {
  meta: {
    previewImageId: 'b7aa3581-2d25-4d9e-1063-f3a40ff3b700',
    descriptor: "The Leap's very own theme, funky colours all around.",
  },
  colorVariations: [
    {
      name: 'Off white',
      bgStyle: 'solid',
      bgHue: 340,
      bgLum: 94,
      uiHue: 340,
      uiLum: 100,
      saturation: 10,
      fontColor: 'brown',
    },
    {
      name: 'Blood orange',
      bgStyle: 'solid',
      bgHue: 7,
      bgLum: 67,
      uiHue: 68,
      uiLum: 100,
      saturation: 90,
      fontColor: 'gray-1',
    },
    {
      name: 'Forest',
      bgStyle: 'solid',
      bgHue: 115,
      bgLum: 61,
      uiHue: 115,
      uiLum: 100,
      saturation: 45,
      fontColor: 'gray-1',
    },
    {
      name: 'Sky blue',
      bgStyle: 'solid',
      bgHue: 201,
      bgLum: 69,
      uiHue: 208,
      uiLum: 100,
      saturation: 89,
      fontColor: 'gray-1',
    },
    {
      name: 'Lilac',
      bgStyle: 'solid',
      bgHue: 298,
      bgLum: 84,
      uiHue: 326,
      uiLum: 100,
      saturation: 36,
      fontColor: 'gray-1',
    },
  ],
  parameters: {
    preset: PresetNames.THE_LEAP,
    layout: 'stack',
    colorVariation: 'Off white',
    spacing: 32,
    blend: 10,
    displayFont: FontNames.TOBIAS,
    bodyFont: FontNames.FAVORIT,
    style: 'leap',
    styleStrength: 25,
    bgStyle: 'solid',
    bgHue: 340,
    bgLum: 94,
    uiHue: 340,
    uiLum: 100,
    saturation: 10,
    fontColor: 'brown',
    borderRadius: 100,
    fontSize: 27,
    textStrength: 0,
  },
};

export const Presets: Preset[] = [
  PresetTheLeap,
  PresetEpic,
  PresetNova,
  PresetAura,
  PresetMystique,
  PresetCandy,
  PresetLuna,
  PresetMinimal,
  PresetPulse,
];
