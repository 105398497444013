export const SIGNUP_FORM_TYPE = 'signupForm';
export const CUSTOM = 'custom';
export const DRAFT = 'DRAFT';

export enum PresetNames {
  THE_LEAP = 'TheLeap',
  AURA = 'Aura',
  MYSTIQUE = 'Mystique',
  NOVA = 'Nova',
  EPIC = 'Epic',
  LUNA = 'Luna',
  TUNDRA = 'Tundra',
  MINIMAL = 'Minimal',
  PULSE = 'Pulse',
  CANDY = 'Candy',
}
